<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0 transparent">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Reclutadora - Puesto</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(puestos, 'Puestos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>

              <!-- Catalago en forma de carts -->
              <v-col cols="12" sm="6" lg="4" v-for="(a, i) in puestos" :key="i" class="mb-5">
                <v-card class="shadowCard">
                  <v-row>
                    <v-col cols="12">
                      <v-card-text align="center" class="pa-0 pt-2">
                       <h2><b>{{a.puesto}}</b><br/></h2>
                      </v-card-text>
                      
                      <v-card-title primary-title>
                        <span class="text-subtitle-1">Reclutadora</span> 
                        <v-btn 
                          color="blue" 
                          icon 
                          @click="addReclutadora(a)"
                         >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </v-card-title>
                      
                      <v-divider></v-divider>
                      
                      <!-- Puestos del departamento -->
                      <v-data-table
                        :headers="headers"
                        :items="a.reclutadoras"
                        fixed-header
                        :height="200"
                        hide-default-footer
                        dense
                        :items-per-page="100"
                        :mobile-breakpoint="250"
                      >
                        <template v-slot:item.actions="{ item }">

                          <v-icon
                            color="red"
                            small
                            @click="dialogDelete = true, itemDeletePuesto = item"
                          >
                            mdi-delete
                          </v-icon>

                        </template>
                        </v-card-title>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="reclutadora"
            :items="reclutadoras"
            label="Reclutadora"
            item-value="iderp"
            item-text="name"
            filled
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-btn 
            color="black"
            dark
            tile
            small
            @click="close"
          > 
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar 
          </v-btn>

          <v-spacer></v-spacer>
          
          <v-btn 
            color="green" 
            dark
            tile
            small 
            @click="save"
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title primary-title class="text-subtitle-1">
          <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
        </v-card-title>
          <v-card-actions class="pt-6">
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn
              color="error"
              text
              large
              class="mr-2"
              @click="dialogDelete = false"
              >No, cancelar</v-btn
            >
            <v-spacer></v-spacer>
            <!-- Guardar la información  -->
            <v-btn
              color="#5C5C5C"
              dark
              class="elevation-6"
              large
              @click="deleteItemConfirm()"
              >Si</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,

    headers: [
      { text: "Reclutadora", value: "usuario" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    puestos: [],
    puesto: null,

    reclutadoras: [],
    reclutadora: null,

    // tabla
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    itemDeletePuesto: null

  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar reclutadora"
        : "Agregar reclutadora";
    },

    filterEncargadas() {
      if (this.departamento) {
        return this.Infoencargadas.encargadas.filter((el) => {
          return el.iddepartamento == this.departamento;
        });
      } else {
        return this.Infoencargadas.encargadas;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.getUsuarios();
  },

  methods: {
    getUsuarios() {
      this.cargar = true
      this.$http.get("reclutadoras").then((response) => {
        this.reclutadoras  = response.data;
        this.cargar        = false
      }).catch((error) => {
        this.validarError( error.response.data.message )
      }).finally(() => { this.cargar = false });
    },
    initialize() {
      this.puestos = [];
      this.cargar = true;
      this.$http.get("encargadas.get").then((response) => {

        this.puestos = response.data.encargadas

        this.cargar = false;
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },


    addReclutadora(item) {
      
      this.puesto = item
      this.dialog = true;

    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItemConfirm( ){
      // Activamos el loader
      this.cargar = true;

      this.$http.put("encargadas.update/" + this.itemDeletePuesto.idencargada_departamento, this.itemDeletePuesto).then((response) => {
        this.validarSuccess('Datos grabados correctamente')
        this.reclutadora = null
        this.dialogDelete = false
        this.initialize();
        this.close();
        this.cargar = false;
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    save() {
      // Activamos el loader
      this.cargar = true;
      // Lo mandapos por el EP
      const payload = {
        idpuesto: this.puesto.idpuesto,
        idusuarios: this.reclutadora
      }

      this.$http.post("encargadas.add", payload).then((response) => {
        this.validarSuccess('Datos grabados correctamente')
        this.reclutadora = null
        this.initialize();
        this.close();
        this.cargar = false;
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

  },
};
</script>


